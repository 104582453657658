import React from 'react'
import { Box, FooterLink } from './styles'

export const Footer = () => (
  <Box>
    <FooterLink
      href={`https://docs.mein-schnelltest.com/Impressum_${process.env.REACT_APP_COMPANY}.pdf`}
    >
      Impressum
    </FooterLink>
    <FooterLink
      href={`https://docs.mein-schnelltest.com/Datenschutzinformationen_${process.env.REACT_APP_COMPANY}.pdf`}
    >
      Datenschutzinformationen
    </FooterLink>
  </Box>
)

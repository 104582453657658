import React, { FC, useRef } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'

export const Verification: FC<{ isLoading: boolean; onCheckCode: (code: string) => void }> = ({
  isLoading,
  onCheckCode
}) => {
  const textRef = useRef<HTMLInputElement | null>(null)

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onCheckCode(textRef.current.value)
    }
  }

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <div>
          <Typography color='textPrimary' gutterBottom variant='h4'>
            Ergebnis-Abfrage
          </Typography>
          <Typography color='textSecondary' variant='body2'>
            Geben Sie Ihr Geburtsdatum im Format DD.MM.YYYY ein, um das Ergebnis zu erhalten
            (Beispiel: 24.12.1999)
          </Typography>
        </div>
        <Box
          sx={{
            width: 50,
            '& > img': {
              maxWidth: '100%',
              height: 'auto'
            }
          }}
        >
          <img alt='logo' src='/static/icons/logo.png' />
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          mt: 3
        }}
      >
        <TextField
          autoFocus
          fullWidth
          label='Geburtsdatum'
          margin='normal'
          name='password'
          type='text'
          variant='outlined'
          inputRef={textRef}
          onKeyDown={handleKeyDown}
        />

        <Box sx={{ mt: 2 }}>
          <Button
            color='primary'
            disabled={isLoading}
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            onClick={() => onCheckCode(textRef.current.value)}
          >
            Weiter
          </Button>
        </Box>
      </Box>
    </>
  )
}

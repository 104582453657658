import { Theme } from '@emotion/react'
import { SxProps } from '@mui/material'
import React, { FC } from 'react'

interface LogoProps {
  sx?: SxProps<Theme>
}

const Logo: FC<LogoProps> = (props) => (
  <div {...props}>
    <img alt='logo' height='52' width='52' src='/static/icons/logo.png' />
  </div>
)

export default Logo

import styled from 'styled-components'

export const Box = styled.div`
  background: black;
  position: fixed;
  bottom: 0;
  padding: 5px;
  padding-left: 15px;
  width: 100%;
  z-index: 999;
`

export const FooterLink = styled.a`
  color: #fff;
  margin-bottom: 10px;
  font-size: 18px;
  text-decoration: none;
  padding-top: 10px;
  padding-right: 10px;
  &:hover {
    color: red;
    transition: 200ms ease-in;
  }
`

import { Modal, Paper, CircularProgress } from '@mui/material'
import React, { FC } from 'react'

export const LoadingSpinner: FC<{
  isVisible: boolean
}> = ({ isVisible }) => (
  <Modal
    open={isVisible}
    style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
    disablePortal
    disableEnforceFocus
    disableAutoFocus
  >
    <Paper
      elevation={12}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '10px'
        }}
      >
        <CircularProgress />
      </div>
    </Paper>
  </Modal>
)

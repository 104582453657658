import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Login } from './pages/Login'
import { NotFound } from './pages/NotFound'
import { SnackbarProvider } from 'notistack'

function App() {
  return (
    <SnackbarProvider dense maxSnack={3}>
      <Router>
        <Routes>
          <Route path='/:token' element={<Login />}></Route>
          <Route path='*' element={<NotFound />}></Route>
        </Routes>
      </Router>
    </SnackbarProvider>
  )
}

export default App

import { useTheme } from '@emotion/react'
import { Box, Container, Typography, Button, Card, CardContent } from '@mui/material'
import React, { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'

export const NotFound: FC = () => {
  const theme = useTheme()

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container maxWidth='lg' sx={{ py: '20px' }}>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Typography align='center' color='textPrimary' variant='h2'>
                404: Die gesuchte Seite existiert nicht
              </Typography>
              <Typography align='center' color='textSecondary' sx={{ mt: 0.5 }} variant='subtitle2'>
                Um Ihr Ergebnis zu erhalten, müssen Sie den Link aus Ihrer Mail aufrufen (inkl. dem
                Code hinter https://ergebnis.mein-schnelltest.com/).
              </Typography>
              <Typography align='center' color='textSecondary' sx={{ mt: 0.5 }} variant='subtitle2'>
                Bei Problemen wenden Sie sich an den Kundensupport unter der Mail:{' '}
                {<a href='info@mein-schnelltest.com'>info@mein-schnelltest.com</a>}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 6
                }}
              >
                <Box
                  alt='Under development'
                  component='img'
                  src={`/static/error.svg`}
                  sx={{
                    height: 'auto',
                    maxWidth: '100%',
                    width: 400
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 6
                }}
              >
                <Button
                  color='primary'
                  component='a'
                  href='https://mein-schnelltest.com'
                  variant='outlined'
                >
                  Zurück zu mein-schnelltest.com
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  )
}
